import sanitizeHtml from 'sanitize-html';

/**
 * Sanitize the provided HTML string.
 *
 * authorizedHtmlItems contains all basics html items from mdn doc that aren't already in the default config of sanitized html lib
 *
 * @param {string} html - HTML string to sanitize.
 * @returns {string} The sanitized string.
 */
export default (html) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(authorizedHtmlItems),
    allowedAttributes: false,
  });
};

const authorizedHtmlItems = [
  'img',
  'area',
  'audio',
  'map',
  'track',
  'video',
  'embed',
  'iframe',
  'object',
  'picture',
  'portal',
  'source',
  'math',
  'canvas',
  'noscript',
  'del',
  'ins',
  'button',
  'datalist',
  'fieldset',
  'form',
  'input',
  'label',
  'legend',
  'meter',
  'optgroup',
  'option',
  'output',
  'progress',
  'select',
  'textarea',
  'details',
  'dialog',
  'summary',
  'slot',
  'template',
  'body',
  'html',
  'base',
  'head',
  'link',
  'meta',
  'title',
  // SVG parts
  'svg',
  'a',
  'animate',
  'animateMotion',
  'animateTransform',
  'circle',
  'clipPath',
  'cursor',
  'defs',
  'desc',
  'ellipse',
  'feBlend',
  'feColorMatrix',
  'feComponentTransfer',
  'feComposite',
  'feConvolveMatrix',
  'feDiffuseLighting',
  'feDisplacementMap',
  'feDistantLight',
  'feDropShadow',
  'feFlood',
  'feFuncA',
  'feFuncB',
  'feFuncG',
  'feFuncR',
  'feGaussianBlur',
  'feImage',
  'feMerge',
  'feMergeNode',
  'feMorphology',
  'feOffset',
  'fePointLight',
  'feSpecularLighting',
  'feSpotLight',
  'feTile',
  'feTurbulence',
  'filter',
  'fontface-format>',
  'fontface-name>',
  'fontface-src>',
  'fontface-uri>',
  'fontface>',
  'font',
  'foreignObject',
  'g',
  'glyph',
  'glyphRef',
  'hkern',
  'image',
  'line',
  'linearGradient',
  'marker',
  'mask',
  'metadata',
  'missingglyph>',
  'mpath',
  'path',
  'pattern',
  'polygon',
  'polyline',
  'radialGradient',
  'rect',
  'set',
  'stop',
  'svg',
  'switch',
  'symbol',
  'text',
  'textPath',
  'title',
  'tref',
  'tspan',
  'use',
  'view',
  'vkern',
];
